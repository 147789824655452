import React, { Component } from "react";
import { Button } from "reactstrap";

import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSync,
  faExclamationTriangle,
} from "@fortawesome/pro-light-svg-icons";

import axios from "axios";
import config from "../config";
import moment from "moment";
import "react-dates/initialize";
// import { DateRangePicker, isInclusivelyBeforeDay } from "react-dates";
import ComponentLoading from "../components/ComponentLoading";
import DatePicker from "react-datepicker";

import "../styles/containers/Reports.css";
// import "react-dates/lib/css/_datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { Auth } from "aws-amplify";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import DashboardContainer from "../components/DashboardContainer";

export default class ReportsDashboard extends Component {
  constructor(props) {
    super(props);

    let start = moment(new Date());
    let end = moment(start)
      .add(5, "days")
      .subtract(1, "second");

    this.state = {
      session: null,
      parts_url: null,
      isLoading: true,
      emptyResults: false,
      startDate: moment().subtract(1, "day"),
      endDate: moment(),
      start: start,
      end: end,
      machine_id: null,
      group: null,
      charts: {
        bar: {
          data: [],
        },
        pie: {
          data: [],
          colors: [],
        },
        machine: "",
        parts: 0,
      },
      account: {
        id: null,
        name: null,
        contract_end_date: null,
        groups: [],
        machines: [],
        dashboard_id: null,
      },
      selectOptions: [],
      selectedOption: 1,
    };

    this.reloadPage = this.reloadPage.bind(this);
    this.runPartsReport = this.runPartsReport.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.applyCallback = this.applyCallback.bind(this);
    this.renderVanillaPicker = this.renderVanillaPicker.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
  }

  applyCallback(startDate, endDate) {
    this.setState({
      start: startDate,
      end: endDate,
    });
  }

  renderVanillaPicker() {
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(start)
      .add(1, "days")
      .subtract(1, "seconds");
    let ranges = {
      "Today Only": [moment(start), moment(end)],
      "Yesterday Only": [
        moment(start).subtract(1, "days"),
        moment(end).subtract(1, "days"),
      ],
      "3 Days": [moment(start).subtract(3, "days"), moment(end)],
    };
    let local = {
      format: "DD-MM-YYYY HH:mm",
      sundayFirst: false,
    };
    let maxDate = moment(start).add(24, "hour");
    return (
      <div>
        <DateTimeRangeContainer
          ranges={ranges}
          start={this.state.start}
          end={this.state.end}
          local={local}
          maxDate={maxDate}
          applyCallback={this.applyCallback}
        />
      </div>
    );
  }

  onClick() {
    let newStart = moment(this.state.start).subtract(3, "days");

    this.setState({ start: newStart });
  }

  componentDidMount = () => {
    this.getChartData();
    this.getAccountData();
  };

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  getChartData = async () => {
    // Get the user object from the parent

    await Auth.currentSession().then((data) =>
      this.setState({ session: data })
    );

    let self = this;
    // Start building the query request string by parsing out the variables from
    // the query string in the browser
    let qs = require("query-string");
    let parsed = qs.parse(window.location.search);

    // Set defaults
    let startDate = this.state.startDate.toISOString();
    let endDate = this.state.endDate.toISOString();

    // Assign the startDate and endDate state vars (if they are set)
    if (parsed.startDate !== undefined && parsed.endDate !== undefined) {
      self.setState({
        startDate: moment(parsed.startDate, "YYYY-MM-DD"),
        endDate: moment(parsed.endDate, "YYYY-MM-DD"),
      });

      startDate = parsed.startDate;
      endDate = parsed.endDate;
    }

    if (parsed.machine_id !== undefined) {
      self.setState({
        machine_id: parsed.machine_id,
      });
    }

    if (parsed.group !== undefined) {
      self.setState({
        group: parsed.group,
      });
    }

    // Build the query string
    let queryString = "?";
    queryString += "start_date=" + startDate + "&";
    queryString += "end_date=" + endDate + "&";

    if (parsed.machine_id !== undefined) {
      queryString += "machine_id=" + parsed.machine_id + "&";
    } else if (parsed.group !== undefined) {
      queryString += "group=" + parsed.group;
    } else if (parsed.machine_id === undefined && parsed.group === undefined) {
      queryString += "group=all_machines";
    }

    axios
      .get(config.cognito.API_MC_TIMINGS + queryString, {
        headers: {
          Authorization: this.state.session.idToken.jwtToken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const results = response.data;

        // If the request has results
        if (Object.keys(results).length > 0) {
          let chartData = [["State", "Minutes", { role: "style" }]];
          let pieChartColors = [];

          for (let key in results.results[0]) {
            if (key !== "parts" && key !== "machine" && key !== "group") {
              let arrIndex,
                colorIndex = 0;
              switch (key) {
                case "cycle_time":
                  arrIndex = 1;
                  colorIndex = 0;
                  break;
                case "setup_time":
                  arrIndex = 2;
                  colorIndex = 1;
                  break;
                case "downtime":
                  arrIndex = 3;
                  colorIndex = 2;
                  break;
                case "idle_time":
                  arrIndex = 4;
                  colorIndex = 3;
                  break;
                default:
                  arrIndex = 5;
                  colorIndex = 4;
              }

              chartData.splice(arrIndex, 0, [
                key,
                parseInt(results.results[0][key]),
                this.getChartColors(key),
              ]);

              pieChartColors.splice(colorIndex, 0, this.getChartColors(key));
            }
          }

          self.setState({
            isLoading: false,
            emptyResults: false,
            charts: {
              bar: {
                data: chartData,
              },
              pie: {
                data: chartData,
                colors: pieChartColors,
              },
              parts: results.results[0].parts,
              machine:
                results.results[0].machine !== undefined
                  ? results.results[0].machine
                  : results.results[0].group,
            },
          });
        } else {
          self.setState({
            isLoading: false,
            emptyResults: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  getBarChartOptions() {
    return {
      legend: {
        position: "none",
      },
      backgroundColor: "#343a40",
      chartArea: {
        left: 75,
        top: 0,
        bottom: 50,
        width: "100%",
        height: "100%",
      },
      tooltip: {
        showColorCode: false,
      },
      hAxis: {
        minValue: 0,
      },
    };
  }

  getPieChartOptions() {
    return {
      legend: {
        position: "none",
      },
      backgroundColor: "#343a40",
      pieSliceBorderColor: "#343a40",
      colors: this.state.charts.pie.colors,
      fontSize: 20,
      chartArea: {
        left: 50,
        top: 50,
        bottom: 50,
        right: 50,
        width: "100%",
        height: "100%",
      },
    };
  }

  getChartColors(state) {
    switch (state) {
      case "setup_time":
        return "yellow";
      case "idle_time":
        return "blue";
      case "downtime":
        return "#BF2A45";
      case "downtme":
        return "#BF2A45";
      case "cycle_time":
        return "#28A745";
      default:
        return "#37718E";
    }
  }

  reloadPage() {
    let startDate = this.state.startDate.toISOString();
    let endDate = this.state.endDate.toISOString();
    let machine = this.state.machine_id;
    let group = this.state.group;

    // Build the push state
    let pushState = "";
    pushState += "startDate=" + startDate + "&";
    pushState += "endDate=" + endDate + "&";
    pushState += machine !== null ? "machine_id=" + machine : "";
    pushState += group !== null ? "group=" + group : "";

    this.props.history.push({
      pathname: "/utilreports/",
      search: pushState,
    });

    this.setState({
      isLoading: true,
    });

    this.getChartData();
  }

  runPartsReport() {
    let startDate = this.state.startDate.toISOString();
    let endDate = this.state.endDate.toISOString();
    let machine = this.state.machine_id;
    let group = this.state.group;

    // Build the push state
    let pushState = "";
    pushState += "startDate=" + startDate + "&";
    pushState += "endDate=" + endDate + "&";
    pushState += machine !== null ? "machine_id=" + machine : "";
    pushState += group !== null ? "group=" + group : "";

    this.props.history.push({
      pathname: "/reports/",
      search: pushState,
    });

    this.setState({
      isLoading: true,
    });

    this.getPartsData();
  }

  compilePresets() {
    let options = [];

    let groupOptions = this.state.account.groups.map((group) => {
      return { value: group.id, label: group.name, type: "group" };
    });

    options.push({ label: "Groups", options: groupOptions });

    let machineOptions = this.state.account.machines.map((machine) => {
      return {
        value: machine.machine_id,
        label: machine.machine_name,
        type: "machine",
      };
    });

    options.push({ label: "Machines", options: machineOptions });

    this.setState({
      selectOptions: options,
    });
  }

  async getPartsData() {
    let self = this;
    const session = await Auth.currentSession();

    let startDate = this.state.startDate.toISOString();
    let endDate = this.state.endDate.toISOString();
    let machineId = this.state.machine_id;
    let group = this.state.group;

    // Build the query string
    let queryString = "?";
    queryString += "start_date=" + startDate + "&";
    queryString += "end_date=" + endDate + "&";
    if (machineId != null) {
      queryString += "machine_id=" + machineId + "&";
    }

    if (group != null) {
      queryString += "group=" + group + "&";
    }

    const partsUrl = config.cognito.API_MC_PARTS + queryString;
    console.log(partsUrl);
    // Get the account data
    axios
      .get(partsUrl, {
        headers: {
          Authorization: session.idToken.jwtToken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if ("url" in response.data) {
          self.setState({
            parts_url: response.data.url,
            isLoading: false,
          });
        } else {
          console.log("Reports url not found..");
        }
      });
  }

  async getAccountData() {
    let self = this;
    const session = await Auth.currentSession();

    // Get the account data
    axios
      .get(config.cognito.API_MC_ACCOUNT, {
        headers: {
          Authorization: session.idToken.jwtToken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const results = response.data.results[0];

        self.setState(
          {
            account: {
              id: results.account_id,
              name: results.account_name,
              contract_end_date: results.contract_end_date,
              groups: results.groups,
              machines: results.machines,
              dashboard_id: results.dashboard_id || null,
            },
          },
          () => {
            this.compilePresets();
          }
        );
      });
  }

  handleChangeStart(date) {
    this.setState({
      startDate: moment(date),
    });
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: moment(date),
    });
  }

  handleChange(selected) {
    let machine,
      group = null;
    if (selected !== null) {
      machine = selected.type === "machine" ? selected.value : null;
      group = selected.type === "group" ? selected.value : null;
    } else {
      group = "all_machines";
      machine = null;
    }

    this.setState(
      {
        selectedOption: selected,
        group: group,
        machine_id: machine,
      }
      // () => {
      //     this.reloadPage();
      // }
    );
  }

  formatGroupLabel = (data) => {
    const groupStyles = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    const groupBadgeStyles = {
      backgroundColor: "#EBECF0",
      borderRadius: "2em",
      color: "#172B4D",
      display: "inline-block",
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: "1",
      minWidth: 1,
      padding: "0.16666666666667em 0.5em",
      textAlign: "center",
    };

    return (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    );
  };

  ReportsHeader() {
    var parts_url = "";
    if (this.state.parts_url != null) {
      parts_url = (
        <div className="text-secondary">
          <FontAwesomeIcon icon={faExclamationTriangle} /> Report generation
          successful :{" "}
          <a className="text-success" href={this.state.parts_url}>
            Download Report
          </a>
        </div>
      );
    }

    if (this.state.isLoading) {
      return <ComponentLoading />;
    }

    var response = (
      <div>
        <div className="row h-100">
          <div className="col-md-3 my-auto">
            <Select
              isClearable
              className="selectOptions"
              value={this.state.selectedOption}
              onChange={this.handleChange}
              options={this.state.selectOptions}
              placeholder={"All Machines"}
              formatGroupLabel={this.formatGroupLabel}
            />
          </div>
          <div className="col-md-4 text-center text-sm-right">
            <DatePicker
              selected={this.state.startDate.toDate()}
              selectsStart
              startDate={this.state.startDate.toDate()}
              endDate={this.state.endDate.toDate()}
              onChange={this.handleChangeStart}
              dateFormat="MM/dd/yyyy h:mm aa"
              timeFormat="HH:mm"
              showTimeSelect
              timeCaption="Time"
              className="form-control"
            />{" "}
            <DatePicker
              selected={this.state.endDate.toDate()}
              selectsEnd
              startDate={this.state.startDate.toDate()}
              endDate={this.state.endDate.toDate()}
              onChange={this.handleChangeEnd}
              dateFormat="MM/dd/yyyy h:mm aa"
              timeFormat="HH:mm"
              showTimeSelect
              timeCaption="Time"
              className="form-control"
            />
          </div>

          <div className="col-md-5 text-center text-sm-right">
            <Button
              color="secondary"
              disabled={
                this.state.endDate.diff(this.state.startDate, "days") > 7
              }
              className="machine-button"
              onClick={this.runPartsReport}
            >
              <FontAwesomeIcon icon={faSync} /> Generate Parts Report
            </Button>{" "}
            <Button
              color="secondary"
              disabled={
                this.state.endDate.diff(this.state.startDate, "days") > 7
              }
              className="machine-button"
              onClick={this.reloadPage}
            >
              <FontAwesomeIcon icon={faSync} /> Generate Efficiency Report
            </Button>
          </div>
        </div>

        {this.state.endDate.diff(this.state.startDate, "days") > 7 && (
          <div className="text-danger text-center mt-1">
            Reports can only be generated for a maximum of 7 days.
          </div>
        )}

        <div className="row mb-3">
          <div className="col" />
          <div className="col">
            <div className="col justify-content-md-center">{parts_url}</div>
          </div>
          <div className="col" />
        </div>

        {this.state.account.dashboard_id && this.state.session && (
          <DashboardContainer
            id={this.state.account.dashboard_id}
            session={this.state.session}
          />
        )}
      </div>
    );

    return response;
  }

  // getDashboardOptions() {
  //   const options = {
  //     url:
  //       "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/dashboardId?isauthcode=true&identityprovider=quicksight&code=authcode",
  //     container: document.getElementById("embeddingContainer"),
  //     parameters: {
  //       country: "United States",
  //       states: ["California", "Washington"],
  //     },
  //     scrolling: "no",
  //     height: "700px",
  //     iframeResizeOnSheetChange: false, // use this option in combination with height: AutoFit, to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
  //     width: "1000px",
  //     locale: "en-US",
  //     footerPaddingEnabled: true,
  //     sheetId: "YOUR_SHEETID", // use this option to specify initial sheet id to load for the embedded dashboard
  //     sheetTabsDisabled: false, // use this option to enable or disable sheet tab controls in dashboard embedding
  //     printEnabled: false, // use this option to enable or disable print option for dashboard embedding
  //   };

  //   var dashboard = embedDashboard(options);
  // }

  render() {
    return <React.Fragment>{this.ReportsHeader()}</React.Fragment>;
  }
}
