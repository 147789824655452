import React from "react";
import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import { useState } from "react";
import axios from "axios";
import config from "../config";

function DashboardContainer({ id, session }) {
  const dashboardRef = React.createRef();
  const [dashboardUrl, setDashboardUrl] = useState(null);
  const [dashboardUrlError, setDashboardUrlError] = useState(false);

  const getDashboardUrl = () => {
    axios
      .get(config.cognito.API_DASH_URL, {
        headers: {
          Authorization: session.idToken.jwtToken,
          "Content-Type": "application/json",
        },
      })
      .then(({ data }) => setDashboardUrl(data.url))
      .catch((err) => setDashboardUrlError(true));
  };

  React.useEffect(() => {
    getDashboardUrl();
  }, []);

  React.useEffect(
    () => {
      if (dashboardUrl) {
        embed();
      }
    },
    [dashboardUrl]
  );

  const embed = () => {
    const options = {
      url: dashboardUrl,
      container: dashboardRef.current,
      scrolling: "no",
      height: "700px",
      width: "100%",
      locale: "en-US",
      footerPaddingEnabled: true,
    };
    embedDashboard(options);
  };

  if (dashboardUrlError) {
    return <div className="text-center">Error fetching Dashboard URL.</div>;
  }

  if (!dashboardUrl) {
    return <div className="text-center">Loading...</div>;
  }

  return <div ref={dashboardRef} />;
}

export default DashboardContainer;
