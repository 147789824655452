import React, { Component } from "react";
import Select from "react-select";
import "../styles/containers/Home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "axios";
import config from "../config";
import { Auth } from "aws-amplify";

import ComponentLoading from "../components/ComponentLoading.js";

const NoDataIndication = () => (
  <div className="table-loading text-center">
    <h4 className="font-weight-light">
      <FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" />
      No results found
    </h4>
  </div>
);

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      machines: [],
      relations: [],
      isLoading: true,
      rSelected: 1,
      selectOptions: [],
      selectedOption: 1,
      timerId: 0,
    };
  }

  componentDidMount = () => {
    this.getGroupData();
    this.getDeviceData();

    let timerId = setInterval(() => {
      this.getDeviceData();
    }, 1000 * 60);
    this.setState({ timerId: timerId });
  };

  componentWillUnmount = () => {
    clearTimeout(this.state.timerId);
  };

  compare = (a, b) => {
    const machine_a = a.device;
    const machine_b = b.device;

    if (machine_a === undefined || machine_b === undefined) return 0;

    let comparison = 0;
    if (machine_a.localeCompare(machine_b) > 0) comparison = 1;
    else if (machine_a.localeCompare(machine_b) < 0) comparison = -1;
    else comparison = 0;

    return comparison;
  };

  getDeviceData = async () => {
    const session = await Auth.currentSession();

    let self = this;
    // Request the data from the API
    axios
      .get(config.cognito.API_MC_STATUS2, {
        headers: {
          Authorization: session.idToken.jwtToken,
          "Content-Type": "application/json",
        },
      })
      .then(function(response) {
        const results = response.data;
        // const results = [{"machine_id": "123456200", "machine": "Lathe2", "time_stamp": "2019-01-12T19:23:37", "status": "Setup-1", "color": "yellow"}];
        const tmpArray = [];

        let filtered = self.state.relations.filter((group) => {
          return group.group_id === self.state.rSelected;
        });

        for (let i = 0; i < results.length; i++) {
          let showLength = filtered.filter(
            (group) => group.machine_id === results[i].machine_id
          );
          if (self.state.rSelected === 1 || showLength.length > 0) {
            tmpArray.push({
              id: i,
              uuid: results[i].machine_id,
              device: results[i].machine,
              status: results[i].current_status,
              color: results[i].current_status_color,
              timestamp: results[i].time_stamp,
              current_status_duration: results[i].current_status_duration,
              operator_name: results[i].operator_name || "",
              program_number:
                results[i].program_number || "",
            });
          }
        }

        console.log(tmpArray);

        self.setState({
          machines: tmpArray,
          isLoading: false,
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  async getGroupData() {
    const user = await Auth.currentAuthenticatedUser();
    let self = this;

    // Get the account data
    axios
      .get(config.cognito.API_MC_ACCOUNT, {
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const results = response.data.results[0];

        self.setState(
          {
            groups: results.groups,
            relations: results.relations,
          },
          () => {
            self.compileGroups();
          }
        );
      });
  }

  compileGroups() {
    let options = this.state.groups.map((group) => {
      return { value: group.id, label: group.name };
    });

    this.setState({
      selectOptions: options,
    });
  }

  handleChange = (rSelected) => {
    let value = rSelected !== null ? rSelected.value : 1;
    this.setState(
      {
        rSelected: value,
        selectedOption: rSelected,
        isLoading: true,
      },
      () => {
        this.getDeviceData();
      }
    );
  };

  getColumnOptions = () => {
    return [
      {
        dataField: "id",
        text: "",
        hidden: true,
      },
      {
        dataField: "machine",
        text: "Machine",
        formatter: this.machineFormatter,
        sort: true,
      },
      {
        dataField: "status",
        text: "",
        classes: "text-right",
        headerClasses: (column, colIndex) => {
          return colIndex === 2 ? "text-right" : null;
        },
        sort: false,
      },
      {
        dataField: "program_number",
        text: "Status",
        headerStyle: (colum, colIndex) => {
          return {
            width: "150px",
          };
        },
        classes: (cell, row, rowIndex, colIndex) => {
          switch (row.color) {
            case "green":
              return "status-indicator-success text-center";
            case "yellow":
              return "status-indicator-warning text-center";
            case "red":
              return "status-indicator-danger text-center";
            case "blue":
              return "status-indicator-idle-blue text-center";
            default:
              return "text-center";
          }
        },
        sort: true,
      },
      {
        dataField: "timestamp",
        text: "Duration",
        formatter: this.timestampFormatter,
        classes: "d-none d-sm-table-cell text-right",
        headerClasses: (column, colIndex) => {
          return colIndex === 4 ? "d-none d-sm-table-cell text-right" : null;
        },
        sort: true,
      },
    ];
  };

  getSortingOptions = () => {
    return [
      {
        dataField: "timestamp",
        order: "desc",
      },
    ];
  };

  getRowEventOptions = () => {
    return {
      onClick: (e, row, rowIndex) => {
        this.props.history.push("/reports/?machine_id=" + row.uuid);
      },
    };
  };

  getPaginationOptions = () => {
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total ml-3">
        Showing {from} to {to} of {size} Results
      </span>
    );

    return {
      paginationSize: 5,
      pageStartIndex: 1,
      withFirstAndLast: false,
      prePageText: "Previous",
      nextPageText: "Next",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "All",
          value: this.state.machines.length,
        },
      ],
    };
  };

  statusFormatter = (cell, row) => {
    return <div>hello</div>;
  };

  machineFormatter = (cell, row) => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ display: "inline-block" }}>
          <div>{row.device}</div>
          <small className="d-inline-block d-sm-none text-muted">
            {row.current_status_duration} minutes
          </small>
        </div>
        {row.operator_name !== "" && (
          <div style={{ display: "inline-block", marginLeft: "auto", fontSize: '12px' }}>
            {row.operator_name}
          </div>
        )}
      </div>
    );
  };

  timestampFormatter = (cell, row) => {
    let hideDuration = false;
    // let retVal = null;
    if (row.status === "Machine-Off") hideDuration = true;

    if (hideDuration)
      return (
        <div>
          <div> </div>
        </div>
      );

    return (
      <div>
        <div>{row.current_status_duration} minutes</div>
      </div>
    );
  };

  render() {
    const columns = this.getColumnOptions();
    const defaultSorted = this.getSortingOptions();
    const rowEvents = this.getRowEventOptions();
    const paginationOptions = this.getPaginationOptions();

    return (
      <div className="Home mb-5">
        {this.state.isLoading ? (
          <ComponentLoading />
        ) : (
          <React.Fragment>
            <div className="row mb-3">
              <div className="col-md-3 offset-md-9">
                <Select
                  isClearable
                  className="selectOptions"
                  value={this.state.selectedOption}
                  onChange={this.handleChange}
                  options={this.state.selectOptions}
                  placeholder={"All Machines"}
                />
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              classes="table-striped"
              bordered={false}
              keyField="id"
              data={this.state.machines}
              columns={columns}
              rowEvents={rowEvents}
              pagination={paginationFactory(paginationOptions)}
              noDataIndication={() => <NoDataIndication />}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}
