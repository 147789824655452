import React, { Component } from "react";
import { Form, FormGroup, Input } from "reactstrap";
import LoaderButton from "../components/LoaderButton";
import { Auth } from "aws-amplify";
import alertify from "alertifyjs";
import logo from "../brand.png";
import { faSignInAlt } from "@fortawesome/pro-light-svg-icons";

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isValid: false,
            email: "",
            password: ""
        };
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            const user = await Auth.signIn(
                this.state.email,
                this.state.password
            );

            // we neeed to check if a challenge is returned from cognito
            if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                this.props.userChangePassword(true);
                this.props.setUserObject(user);
            }

            Auth.currentSession().then(user => {
                this.props.setUserObject(user);
                this.props.userHasAuthenticated(true);
            });

            //this.props.userHasAuthenticated(true);
        } catch (e) {
            console.log(e);
            alertify.error("Incorrect username or password. Please try again.");
            this.setState({ isLoading: false });
        }
    };

    render() {
        return (
            <div className="Login">
                <Form onSubmit={this.handleSubmit} className="form-signin">
                    <div className="logo text-center">
                        <img
                            src={logo}
                            alt="Logo"
                            className="d-block mb-1"
                        />
                    </div>
                    <FormGroup>
                        <Input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="E-mail Address"
                            autoFocus
                            value={this.state.email}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Password"
                            value={this.state.password}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <LoaderButton
                        icon={faSignInAlt}
                        block
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Login"
                        loadingText="Logging in…"
                        className="btn-brand-red"
                    />
                </Form>
            </div>
        );
    }
}
