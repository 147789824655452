import React, { Component } from "react";
import { ButtonGroup, Button } from "reactstrap";
import { Auth } from "aws-amplify";
import Select from "react-select";
import moment from "moment";
import axios from "axios";
import Chart from "react-google-charts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faClock,
  faChartBar,
  faSquare,
  faBackward,
  faHistory,
  faSync,
} from "@fortawesome/pro-light-svg-icons";
import DatePicker from "react-datepicker";

import config from "../config.js";
import loader from "../loader.svg";
import ComponentLoading from "../components/ComponentLoading.js";

import "react-datepicker/dist/react-datepicker.css";
import "../styles/containers/Timeline.css";

const NoData = (isLoading) => {
  if (isLoading === true) return <div />;

  return (
    <div className="row mt-5">
      <div className="col-md-12 text-center">
        <h4 className="font-weight-light">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" />
          No results found
        </h4>
      </div>
    </div>
  );
};

export default class Timeline extends Component {
  constructor(props) {
    super(props);

    this.state = {
      charts: {
        data: [],
        colors: [],
        stacked: {
          data: [],
        },
      },
      groups: [],
      machines: [],
      relations: [],
      isLoading: true,
      firstLoad: true,
      isHistoryLoading: false,
      rSelected: 1,
      gSelected: 1,
      selectOptions: [],
      selectedOption: 1,
      chartSize: 1,
      mcListSize: 0,
      startDate: moment()
        .subtract(1, "day")
        .toDate(),
      endDate: moment().toDate(),
      historySelected: false,
    };

    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
  }

  componentDidMount = () => {
    // get the group data (callback in the below function to get chart data
    // once the group data is retrieved)
    this.getGroupData();

    // Reload the page every 60 seconds

    let timerId = setInterval(() => {
      if (!this.state.historySelected) this.getGroupData();
    }, 1000 * 60);
    this.setState({ timerId: timerId });
  };

  componentWillUnmount = () => {
    clearTimeout(this.state.timerId);
  };

  async getDeviceHistoryData() {
    // Get the user object from the parent
    //let user = this.props.getUserObject();
    //const user = await Auth.currentAuthenticatedUser();
    this.setState({ isHistoryLoading: true });
    const session = await Auth.currentSession();
    let self = this;
    let apiUrl;
    //apiUrl = config.cognito.API_MC_HISTORY2 + "?start_date=" + moment(this.state.startDate).toISOString() + "&end_date=" + moment(this.state.endDate).toISOString() ;
    apiUrl =
      config.cognito.API_MC_HISTORY2 +
      "?start_date=" +
      moment(this.state.startDate).format("YYYY-MM-DD[T]HH:mm:ss") +
      "&end_date=" +
      moment(this.state.endDate).format("YYYY-MM-DD[T]HH:mm:ss");

    // Get the history data for the machines
    axios
      .get(apiUrl, {
        headers: {
          Authorization: session.idToken.jwtToken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const results = response.data.results;

        let includeMachines = this.getIncludedMachines();
        let timelineChart = this.timelineChart(results, includeMachines);

        let stackedChartData = this.stackedChart(results, includeMachines);

        self.setState({
          charts: {
            data: timelineChart.data,
            stacked: {
              data: stackedChartData,
            },
          },
          isHistoryLoading: false,
          firstLoad: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isHistoryLoading: false, firstLoad: false });
      });
  }

  async getDeviceData() {
    // Get the user object from the parent
    //let user = this.props.getUserObject();
    //const user = await Auth.currentAuthenticatedUser();

    this.setState({ isLoading: true });
    const session = await Auth.currentSession();
    let self = this;
    let apiUrl = config.cognito.API_MC_HISTORY;

    // Get the history data for the machines
    axios
      .get(apiUrl, {
        headers: {
          Authorization: session.idToken.jwtToken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const results = response.data.results;

        let includeMachines = this.getIncludedMachines();
        let timelineChart = this.timelineChart(results, includeMachines);

        let stackedChartData = this.stackedChart(results, includeMachines);

        self.setState({
          charts: {
            data: timelineChart.data,
            stacked: {
              data: stackedChartData,
            },
          },
          isLoading: false,
          firstLoad: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false, firstLoad: false });
      });
  }

  getIncludedMachines() {
    let includeMachines = [];
    for (let i = 0; i < this.state.relations.length; i++) {
      if (
        !includeMachines.includes(this.state.relations[i].machine_id) &&
        (this.state.relations[i].group_id === this.state.gSelected ||
          this.state.gSelected === 1)
      ) {
        includeMachines.push(this.state.relations[i].machine_id);
      }
    }

    return includeMachines;
  }

  async getGroupData() {
    //let user = this.props.getUserObject();
    //const user = await Auth.currentAuthenticatedUser();
    const session = await Auth.currentSession();
    let self = this;

    // Get the account data
    axios
      .get(config.cognito.API_MC_ACCOUNT, {
        headers: {
          Authorization: session.idToken.jwtToken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const results = response.data.results[0];

        self.setState(
          {
            groups: results.groups,
            relations: results.relations,
            machines: results.machines,
          },
          () => {
            self.getDeviceData();
            self.compileGroups();
          }
        );
      });
  }

  compileGroups() {
    let options = this.state.groups.map((group) => {
      return { value: group.id, label: group.name };
    });

    this.setState({
      selectOptions: options,
    });
  }

  handleChangeStart(date) {
    this.setState({
      startDate: date,
    });
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date,
    });
  }

  compare = (a, b) => {
    const machine_a = a[0];
    const machine_b = b[0];

    if (machine_a === undefined || machine_b === undefined) return 0;

    let comparison = 0;
    if (machine_a.localeCompare(machine_b) > 0) comparison = 1;
    else if (machine_a.localeCompare(machine_b) < 0) comparison = -1;
    else comparison = 0;
    return comparison;
  };

  timelineChart(data, includeMachines) {
    // Init the series with the default definitions
    let series = [
      [
        { type: "string", id: "machine" },
        { type: "string", id: "machine_id" },
        { type: "string", role: "tooltip", p: { html: true } },
        { type: "string", role: "style" },
        { type: "date", id: "start" },
        { type: "date", id: "end" },
      ],
    ];

    let uniqueMachines = new Set();
    for (let i = 0; i < data.length; i++) {
      let item = data[i];

      // Only push to the series array if the machine is in the include array
      if (includeMachines.includes(item.machine_id)) {
        let nodeColor = this.getColorData(item.last_status_color);
        uniqueMachines.add(item.machine_id);
        series.push([
          item.machine,
          item.machine_id,
          `<div class='w-100 px-3 py-2 text-center font-weight-bold' style='color:white; background-color:${nodeColor};'>${
            item.last_status
          }</div>
                    <div class='p-3'>
                        <p><b>Process Started</b><br /> ${moment(
                          item.last_status_start_time
                        ).format("YYYY-MM-DD HH:mm:ss")}</p>
                        <p><b>Duration</b><br /> ${parseFloat(
                          item.last_status_duration
                        ).toFixed(2)} minutes</p>
                        <p class='mb-0'><b>Machine ID</b><br /> ${
                          item.machine_id
                        }</p>
                    </div>
                    `,
          nodeColor,
          moment(item.last_status_start_time).toDate(),
          moment(item.last_status_end_time).toDate(),
        ]);
      }
    }

    this.setState({ mcListSize: uniqueMachines.size });

    //console.log(series);
    //series.sort(this.compare);
    for (let value of series) {
      console.log(value[0]);
    }
    //console.log(series)
    return {
      data: series,
    };
  }

  stackedChart(data, includeMachines) {
    // Order the data by date
    data = data.sort(function(a, b) {
      return (
        new Date(a.last_status_start_time).getTime() -
        new Date(b.last_status_start_time).getTime()
      );
    });

    // Initial values and empty arrays
    let returnResults = [];
    let header = ["Machine"];
    let machines = [];

    // Loop the initial data
    for (let i = 0; i < data.length; i++) {
      header.push(data[i].last_status_start_time);
      header.push({ role: "style" });
      header.push({ type: "string", role: "tooltip", p: { html: true } });

      if (
        !machines.includes(data[i].machine) &&
        includeMachines.includes(data[i].machine_id)
      ) {
        machines.push(data[i].machine);
      }
    }

    // Push the header onto the return results array
    returnResults.push(header);

    for (let i = 0; i < machines.length; i++) {
      let machine = machines[i];
      let results = [machine];

      // Loop the data and do some checks to insert dummy null data
      for (let j = 0; j < data.length; j++) {
        if (data[j].machine === machine) {
          let nodeColor = this.getColorData(data[j].last_status_color);
          results.push(parseFloat(data[j].last_status_duration));
          results.push(`color: ${nodeColor};`);
          results.push(`<div class='w-100 px-3 py-2 text-center font-weight-bold' style='color:white; background-color:${nodeColor};'>${
            data[j].last_status
          }</div>
                                  <div class='p-3'>
                                    <p><b>Process Started</b><br /> ${moment(
                                      data[j].last_status_start_time
                                    ).format("YYYY-MM-DD HH:mm:ss")}</p>
                                    <p><b>Duration</b><br /> ${parseFloat(
                                      data[j].last_status_duration
                                    ).toFixed(2)} minutes</p>
                                    <p class='mb-0'><b>Machine ID</b><br /> ${
                                      data[j].machine_id
                                    }</p>
                                  </div>`);
        } else {
          results.push(0);
          results.push(`color: red;`);
          results.push(``);
        }
      }

      returnResults.push(results);
    }

    return returnResults;
  }

  getColorData = (color) => {
    switch (color) {
      case "green":
        return "#28A745";
      case "yellow":
        return "yellow";
      case "red":
        return "#BF2A45";
      case "blue":
        return "blue";
      case "dark-blue":
        return "#37718E";
      case "light-blue":
        return "#78A1BB";
      case "purple":
        return "#9E4770";
      case "orange":
        return "black";
      default:
        return "black";
    }
  };

  getFontSize = () => {
    //Set Default value

    let numOfMachines = 5;
    numOfMachines = this.state.mcListSize;

    //Max font size
    if (numOfMachines <= 6) return 25;

    //Min font size
    if (numOfMachines >= 25) return 9;

    let prediction = 218 * Math.pow(numOfMachines, -1.02);
    let size = Math.round(prediction);
    size = size + 2;

    return size;
  };

  getChartOptions = () => {
    let fontSize = this.getFontSize();
    return {
      timeline: {
        showRowLabels: true,
        showBarLabels: false,
        barLabelStyle: {
          fontSize: fontSize,
        },
        rowLabelStyle: {
          color: "#fffcfc",
        },
      },
      tooltip: {
        isHtml: true,
      },

      avoidOverlappingGridLines: false,
      backgroundColor: "#2a3135",
    };
  };

  getStackedChartOptions = () => {
    return {
      chartArea: {
        left: 0,
        top: 0,
        bottom: 100,
        width: "100%",
        height: "100%",
      },
      tooltip: {
        isHtml: true,
      },
      isStacked: "percent",
      hAxis: {
        minValue: 0,
      },
      backgroundColor: "#353a40",
      vAxis: {
        gridlines: {
          color: "transparent",
        },
      },
      bar: {
        groupWidth: "25%",
      },
    };
  };

  updateDateRange(start, end) {
    let self = this;

    this.setState(
      {
        startDate: start,
        endDate: end,
      },
      () => {
        self.getDeviceData();
      }
    );
  }

  getChartEvents = () => {
    return [
      {
        eventName: "select",
        callback: ({ chartWrapper }) => {
          const chart = chartWrapper.getChart();
          const selection = chart.getSelection();
          const dataTable = chartWrapper.getDataTable();

          // Redirect the user to the individual machine page
          this.props.history.push(
            "/reports/?machine_id=" + dataTable.getValue(selection[0].row, 1)
          );
        },
      },
    ];
  };

  getChartLoader = () => {
    return (
      <div className="row justify-content-center">
        <div className="col-md-8 text-center pt-5">
          <img src={loader} alt="Table Loading" height="20" />
        </div>
      </div>
    );
  };

  onRadioBtnClick(rSelected) {
    this.setState({ rSelected });
  }

  onHistoryClick() {
    this.setState((prevState) => ({
      historySelected: !prevState.historySelected,
    }));
  }

  handleChange = (gSelected) => {
    let value = gSelected !== null ? gSelected.value : 1;
    this.setState(
      {
        gSelected: value,
        selectedOption: gSelected,
        isLoading: true,
      },
      () => {
        this.getDeviceData();
      }
    );
  };

  render() {
    // console.log('Active state:' + this.state.rSelected)
    return (
      <div className="Machines">
        {this.state.firstLoad | this.state.isHistoryLoading ? (
          <ComponentLoading />
        ) : (
          <React.Fragment>
            <div className="row mb-3">
              <div className="col-md-auto">
                <ButtonGroup>
                  <Button
                    color="secondary"
                    onClick={() => this.onRadioBtnClick(1)}
                    active={this.state.rSelected === 1}
                  >
                    <FontAwesomeIcon icon={faClock} className="mr-3" />
                    Timeline
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => this.onRadioBtnClick(2)}
                    active={this.state.rSelected === 2}
                  >
                    <FontAwesomeIcon icon={faChartBar} className="mr-3" />
                    Stacked
                  </Button>
                </ButtonGroup>{" "}
                <ButtonGroup>
                  <Button
                    color="secondary"
                    onClick={() => this.onHistoryClick()}
                    active={this.state.historySelected === true}
                  >
                    <FontAwesomeIcon icon={faHistory} className="mr-3" />
                    History
                  </Button>
                </ButtonGroup>{" "}
                <ButtonGroup>
                  {!this.props.getTVView() ? (
                    <Button
                      color="secondary"
                      onClick={() => this.props.setTVView(true)}
                      active={this.state.rSelected === 1}
                    >
                      <FontAwesomeIcon icon={faSquare} className="mr-3" />
                      Display
                    </Button>
                  ) : null}
                  {this.props.getTVView() ? (
                    <Button
                      color="secondary"
                      onClick={() => this.props.setTVView(false)}
                      active={this.state.rSelected === 1}
                    >
                      <FontAwesomeIcon icon={faBackward} />
                    </Button>
                  ) : null}
                </ButtonGroup>
              </div>
              <div className="col-md-2 mt-3 mt-sm-0">
                <Select
                  isClearable
                  className="selectOptions"
                  value={this.state.selectedOption}
                  onChange={this.handleChange}
                  options={this.state.selectOptions}
                  placeholder={"All Machines"}
                />
              </div>
              <div
                className="col-md-4 mt-3 mt-sm-0"
                style={
                  this.state.historySelected === true ? {} : { display: "none" }
                }
              >
                <DatePicker
                  selected={this.state.startDate}
                  selectsStart
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={this.handleChangeStart}
                  dateFormat="MM/dd/yyyy h:mm aa"
                  timeFormat="HH:mm"
                  showTimeSelect
                  timeCaption="Time"
                  className="form-control"
                />{" "}
                <DatePicker
                  selected={this.state.endDate}
                  selectsEnd
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={this.handleChangeEnd}
                  dateFormat="MM/dd/yyyy h:mm aa"
                  timeFormat="HH:mm"
                  showTimeSelect
                  timeCaption="Time"
                  className="form-control"
                />{" "}
                <Button
                  color="secondary"
                  disabled={
                    moment(this.state.endDate).diff(
                      moment(this.state.startDate),
                      "days"
                    ) > 1
                  }
                  onClick={() => this.getDeviceHistoryData()}
                  style={{ marginTop: "-4px" }}
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </div>

            {moment(this.state.endDate).diff(
              moment(this.state.startDate),
              "days"
            ) > 1 && (
              <div className="text-center text-danger mt-2">
                Timeline history can only be generated for 1 day.
              </div>
            )}

            {this.state.charts.data.length > 1 ? (
              <React.Fragment>
                <div
                  style={this.state.rSelected === 1 ? {} : { display: "none" }}
                >
                  <Chart
                    width={"100%"}
                    height={"800px"}
                    chartType="Timeline"
                    chartEvents={this.getChartEvents()}
                    loader={this.getChartLoader()}
                    data={this.state.charts.data}
                    options={this.getChartOptions()}
                  />
                </div>
                <div
                  style={this.state.rSelected === 2 ? {} : { display: "none" }}
                >
                  <Chart
                    width={"100%"}
                    height={"700px"}
                    chartType="ColumnChart"
                    loader={this.getChartLoader()}
                    data={this.state.charts.stacked.data}
                    options={this.getStackedChartOptions()}
                  />
                </div>
              </React.Fragment>
            ) : (
              <NoData loading={this.state.isLoading} />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
