import React, { Component } from "react";
import axios from "axios";

import "../styles/containers/Account.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faObjectGroup, faCogs, faServer } from "@fortawesome/pro-light-svg-icons";
import  { Auth } from 'aws-amplify';
import config from "../config.js";
import ComponentLoading from "../components/ComponentLoading.js";

export default class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account: {
                id: null,
                name: null,
                contract_end_date: "Unknown",
                groups: [],
                machines: []
            },
            isLoading: true
        };
    }

    componentDidMount() {
        this.getAccountData();
    }

    async getAccountData() {
        
        let self = this;
        const session = await Auth.currentSession();

        // Get the account data
        axios
            .get(config.cognito.API_MC_ACCOUNT, {
                headers: {
                    Authorization: session.idToken.jwtToken,
                    "Content-Type": "application/json"
                }
            })
            .then(response => {
                const results = response.data.results[0];

                self.setState({
                    account: {
                        id: results.account_id,
                        name: results.account_name,
                        contract_end_date: results.contract_end_date,
                        groups: results.groups,
                        machines: results.machines
                    },
                    isLoading: false
                });
            });
    }

    renderMachines() {
        let machines = this.state.account.machines;

        let machineList = machines.map(function(machine) {
            return (
                <div key={machine.machine_id} className="row h-100">
                    <div className="col-md-12 col-6 align-self-center mb-2">{machine.machine_name}</div>
                </div>
            );
        });

        return <div>{machineList}</div>;
    }

    renderGroups() {
        let groups = this.state.account.groups;

        let groupList = groups.map(function(group) {
            return (
                <div key={group.id} className="row h-100">
                    <div className="col-md-12 col-6 align-self-center mb-2">{group.name}</div>
                </div>
            );
        });

        return <div>{groupList}</div>;
    }

    render() {
        return (
            <div className="Account">
                {this.state.isLoading ? (
                    <ComponentLoading />
                ) : (
                    <React.Fragment>
                        <div className="container p-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <h4 className="font-weight-light mb-1">{this.state.account.name}</h4>
                                </div>
                                <div className="col-md-6 text-lg-right">
                                    <h4 className="font-weight-light mb-1">Account ID: {this.state.account.id}</h4>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="account-panel">
                                        <p className="label mb-1">
                                            <FontAwesomeIcon icon={faCalendar} className="fa-fw mr-2" />
                                            Contract End Date
                                        </p>
                                        <p className="value">{this.state.account.contract_end_date}</p>
                                    </div>
                                    <div className="account-panel">
                                        <p className="label mb-1">
                                            <FontAwesomeIcon icon={faServer} className="fa-fw mr-2" />
                                            Number of Machines
                                        </p>
                                        <p className="value">{this.state.account.machines.length}</p>
                                    </div>
                                    <div className="account-panel">
                                        <p className="label mb-1">
                                            <FontAwesomeIcon icon={faObjectGroup} className="fa-fw mr-2" />
                                            Number of Groups
                                        </p>
                                        <p className="value">{this.state.account.groups.length}</p>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="account-panel">
                                        <p className="label mb-1">
                                            <FontAwesomeIcon icon={faCogs} className="fa-fw mr-2" />
                                            Machines
                                        </p>
                                        <div className="machine-panel">{this.renderMachines()}</div>
                                    </div>
                                    <div className="account-panel">
                                        <p className="label mb-1">
                                            <FontAwesomeIcon icon={faObjectGroup} className="fa-fw mr-2" />
                                            Groups
                                        </p>
                                        <div className="machine-panel">{this.renderGroups()}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}
