import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/pro-light-svg-icons";
import TabView from "./components/TabView";

import logo from "./brand.png";

import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle
} from "reactstrap";

import { Auth } from "aws-amplify";

import Routes from "./Routes";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            isAuthenticated: false,
            isAuthenticating: true,
            isValid: false,
            userObject: null,
            username: "",
            accountName: "",
            expiryDate: "01/01/2050",
            tvView: false
        };
        

        this.toggle = this.toggle.bind(this);
        this.setUserObject = this.setUserObject.bind(this);
        this.getUserObject = this.getUserObject.bind(this);
        this.setAccountName = this.setAccountName.bind(this);
        this.getAccountName = this.getAccountName.bind(this);
        this.setExpiryDate = this.setExpiryDate.bind(this);
        this.getExpiryDate = this.getExpiryDate.bind(this);
        this.userHasAuthenticated = this.userHasAuthenticated.bind(this);
        this.userChangePassword = this.userChangePassword.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleChangePass = this.handleChangePass.bind(this);
        this.hasValidUserObject = this.hasValidUserObject.bind(this);
    }

    async componentDidMount() {

        try {
            await Auth.currentSession().then(user => {
                this.setUserObject(user);
                this.fullUserName(user.idToken.payload.email);
                this.setAccountName(user.idToken.payload["custom:account_name"]);
                this.setExpiryDate(user.idToken.payload["custom:expiration_date"]);
                this.userHasAuthenticated(true);
            });
           
        } catch (e) {
            if (e !== "No current user") {
                console.log(e);
            }
        }

        this.setState({ isAuthenticating: false });
    }

    setTVView = (value) => {
        this.setState({tvView : value});
    }

    getTVView = () => {
        return this.state.tvView;
    }

    userHasAuthenticated(authenticated) {
        this.setState({ isAuthenticated: authenticated });
    }

    userChangePassword(changePassword) {
        this.setState({ isValid: changePassword });
        this.props.history.push("/change-password");
    }

    setUserObject(user) {
        this.setState({ userObject: user });
    }

    getUserObject() {
        return this.state.userObject;
    }

    hasValidUserObject() {
        return this.state.userObject !== null;
    }

    setAccountName(accountName) {
        this.setState({ accountName });
    }

    fullUserName(username) {
        this.setState({ username: username });
    }

    getAccountName() {
        return this.state.accountName;
    }

    getExpiryDate() {
        return this.state.expiryDate;
    }

    setExpiryDate(expiryDate) {
        this.setState({ expiryDate });
    }

    async handleLogout(event) {
        let self = this;
        await Auth.signOut()
            .then(data => {
                self.userHasAuthenticated(false);
                self.props.history.push("/login");
            })
            .catch(err => console.log(err));
    }

    handleChangePass(event) {
        let self = this;
        self.props.history.push("/changepassword");

    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    renderTabView(childProps) {
        return <div>{this.hasValidUserObject() ? <TabView childProps={childProps} /> : null}</div>;
    }

    render() {

        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userObject: this.state.userObject,
            userHasAuthenticated: this.userHasAuthenticated,
            userChangePassword: this.userChangePassword,
            setUserObject: this.setUserObject,
            getUserObject: this.getUserObject,
            getExpiryDate: this.getExpiryDate,
            setTVView: this.setTVView,
            getTVView: this.getTVView
        };

        
         
        if (this.state.tvView)
        {
            return (
                <div className="Routes">
                        <Routes childProps={childProps} />
                    </div>

            );
        }
        
        return (
            !this.state.isAuthenticating && (
                <div className="App">
                    {this.state.isAuthenticated ? (
                        <React.Fragment>
                            <Navbar color="dark" dark expand="md" className="mb-4">
                                <NavLink tag={Link} to="/" className="navbar-brand">
                                    <img src={logo} alt="Logo" className="mr-3" />
                                </NavLink>
                                <NavbarToggler onClick={this.toggle} />
                                <Collapse isOpen={this.state.isOpen} navbar>
                                    <Nav className="abs-center-x">
                                        <NavItem>
                                            <NavLink tag={Link} to="/account" className="account-name">
                                                {this.getAccountName()}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <Nav className="ml-auto" navbar>
                                        {this.state.isAuthenticated ? (
                                            <UncontrolledDropdown nav inNavbar>
                                                <DropdownToggle nav caret>
                                                    {this.state.isAuthenticated && this.state.username !== ""
                                                        ? this.state.username
                                                        : "Options"}
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <DropdownItem onClick={this.handleLogout}>
                                                        <FontAwesomeIcon icon={faSignOutAlt} className="mr-3" />
                                                        Logout
                                                    </DropdownItem>
                                                    <DropdownItem onClick={this.handleChangePass}>
                                                        <FontAwesomeIcon icon={faSignOutAlt} className="mr-3" />
                                                        Change Password
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        ) : (
                                            <NavItem>
                                                <NavLink tag={Link} to="/login">
                                                    Login
                                                </NavLink>
                                            </NavItem>
                                        )}
                                    </Nav>
                                </Collapse>
                            </Navbar>
                            {this.renderTabView(childProps)}
                        </React.Fragment>
                    ) : null}
                    <div className="Routes">
                        <Routes childProps={childProps} />
                    </div>
                </div>
            )
        );
    }
}

export default withRouter(App);
